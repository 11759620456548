body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #cefffd;
  overflow-x: hidden;
  /* margin: auto; */

  /* justify-content: center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  margin: auto;
  max-width: 1300px;
  width: 100vw;
  justify-content: center;
}

.App-header-fixed-parent {
  /* width: 24rem; */
  height: 100vh;
  background-color: black;
}

.App-header {
  width: 292.38px;
  position: fixed;
  /* top: 0; */
  /* width: inherit;
  /* 706c61 */
  /* background-color: #282c34; */
  background-color: #333333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 4px;
  overflow-y: scroll;
  height: 100vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.App-header::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.test {
  overflow-y: scroll;
  height: 100vh;
}
.App-header img {
  width: 70%;
  border-radius: 100%;
  margin: 1.5rem auto 1.1rem;
  /* height: 50px; */
}

.App-header h3 {
  font-family: "EB Garamond", serif;
  margin: 0 0 0.6rem;
  font-size: 2.4rem;
}

.App-header h4 {
  font-size: 0.9rem;
}

.App-header p {
  /* font-family: "EB Garamond", serif; */
  font-size: 0.8rem;
  color: #706c61;
}

/* .App-header br {
  font-size: 0.8rem;
} */

i {
  margin: 0.4rem;
}
.icon {
  width: 32px !important;
  height: 32px;
  margin: 0.4rem !important;
}

.main-body {
  /* width: 100%; */
  background-color: #ffffff;
  flex-grow: 1;
  min-width: 0;
}
.main-body h1 {
  /* width: 100%; */
  font-size: 3rem;
  margin-bottom: 1rem;
}

.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background-color: rgb(202, 242, 255); */
  background-color: #706c61;
  color: white;
  padding: 2rem 2rem 1rem;
  -webkit-box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  -moz-box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  border: 10px solid #706c61;
}
.about-me-who-am-i {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: rgb(202, 242, 255); */
}
.about-me-who-am-i-div {
  width: 20rem;
  margin: 5rem;
}
.about-me img {
  height: 30rem;
  border-radius: 100%;
  margin: 2rem;
}
.about-me h1 {
  font-family: "EB Garamond", serif;
  font-size: 4.4rem;
}

.my-skills {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(202, 242, 255); */
  background-color: #ffffff;
  /* color: white; */
  padding: 1rem 2rem 2rem;
  /* -webkit-box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  -moz-box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  box-shadow: inset 0px 0px 0px 10px rgb(255, 255, 255);
  border: 10px solid #706c61; */
}
.my-skills-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;

  margin: 2rem 0;
  justify-content: center;
}
.my-skills-row img {
  width: 10rem;
}

.my-skills-img-left {
  margin: 0 3rem 0 0;
}
.my-skills-img-right {
  margin: 0 0 0 3rem;
}

.my-skills-row h3 {
  color: #333333;
}

.my-skills-row-text {
  max-width: 30rem;
  min-width: 200px;
  flex: 1;
}

.my-interests {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2rem;
  background-color: #706c61;
  color: white;
}
.my-interests-lists {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.my-interests-lists ul {
  margin: 0;
}
.my-interests-lists li {
  margin: 1.3em;
}

.my-interests-lists-note {
  max-width: 23rem;
}

.carousel-caption h3,
.carousel-caption p {
  background: rgba(0, 0, 0, 0.4);
  margin: auto;
}

.App-link {
  color: #61dafb;
}

.mr-auto {
  background-color: #333333;
}

.nav-link {
  padding: 0;
  font-size: 1.4rem;
  /* display: flex;
  flex-direction: column; */
}
.navbar-dark,
.bg-dark {
  background-color: #333333 !important;
}
.jumbotron {
  height: 300px;
  background-size: cover !important;
  color: white;
}
.jumbo1 {
  background-image: url("/images/games/bd2.jpg") !important;
}
.jumbo2 {
  background-image: url("/images/games/bdr.png") !important;
}
.jumbo2 {
  background-image: url("/images/games/in4.png") !important;
}
.jumbo3 {
  background-image: url("/images/games/bdr.png") !important;
}
.jumbo4 {
  background-image: url("/images/games/poker.png") !important;
}
.jumbowa1 {
  background-image: url("/images/webapps/tw.png") !important;
}
.jumbowa2 {
  background-image: url("/images/webapps/chat.png") !important;
}
.jumbowa3 {
  background-image: url("/images/webapps/places.png") !important;
}
.jumbowa4 {
  background-image: url("/images/webapps/blocks2.png") !important;
}

.jumbotron span {
  background: rgba(0, 0, 0, 0.4);
}

.game-info {
  text-align: left;
  margin: 0 2rem 2rem;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.game-info .card {
  flex-basis: 19rem;
  flex-shrink: 0;
  margin: 0 2rem 0 0;
}

.card button {
  margin: 0 0.8rem 0 0;
}

.game-info-text {
  flex-grow: 1; /* it can grow */
  flex-shrink: 1; /* it can shrink */
  flex-basis: 340px; /* arbitrary depending on design */
  min-width: 340px; /* equal to flex-basis */
}

.title {
  background-color: #333333;
  padding: 8px 0 18px;
  color: white;
}
.title h1 {
  margin: 0;
}

.resume-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background-color: rgb(202, 242, 255); */
  background-color: #706c61;
  color: white;
  padding: 2rem 2rem 1rem;
  -webkit-box-shadow: inset 0px 0px 0px 6px rgb(255, 255, 255);
  -moz-box-shadow: inset 0px 0px 0px 6px rgb(255, 255, 255);
  box-shadow: inset 0px 0px 0px 6px rgb(255, 255, 255);
  border: 6px solid #706c61;
}
.resume-header h1 {
  font-family: "EB Garamond", serif;
  font-size: 2.4rem;
}
.resume-header h3 {
  font-size: 1rem;
  margin: 0;
}

.resume-title {
  background-color: #706c61;
  color: white;
  font-size: 1.4rem;
  text-align: left;
  border: 4px solid white;
  margin: 0 0 0.6rem 0;
  padding: 0.4rem 0.4rem;
}
.resume-row {
  text-align: left;
  display: flex;
  background-color: white;
  margin: 1rem;
}

.resume-row-info {
  text-align: left;
  display: flex;
  background-color: white;
  margin: 1rem;
  justify-content: center;
}
.resume-row-certs {
  text-align: left;
  display: flex;
  background-color: white;
  justify-content: center;
  margin: 1rem;
}

.resume-date {
  flex-basis: 12rem;
  flex-shrink: 0;
  color: rgb(105, 105, 105);
}
.resume-date h5 {
  font-size: 1rem;
  line-height: 1.7rem;
}

.my-old-flash-games {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.my-old-flash-games > p {
  margin: 0 100px 24px;
}

.my-old-flash-games .h5 {
  margin: 0 0 20px;
}

.my-old-flash-games .card {
  /* flex-basis: 19rem;
  flex-shrink: 0; */
  width: 280px;
  margin: 0 1rem 1rem;
}
.card-img-container {
  height: 278px;
  background-color: rgb(150, 150, 150);
}
.card-img-container .card-img-top {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.card {
  background-color: rgb(230, 230, 230);
}

.card {
  background-color: rgb(230, 230, 230);
}

/* .game-info p {
  margin-left: 2rem;
} */
.side-link {
  margin: 0;
}

.fab {
  margin: 0;
}

.side-link:link {
  color: #bbbbbb;
  text-decoration: none;
}
.side-link:visited {
  color: #bbbbbb;
  text-decoration: none;
}
.side-link:hover {
  color: #ffffff;
  text-decoration: none;
}

.App-header-mobile {
  display: none;
}
.App-header-desktop {
  display: block;
  flex-basis: 292.38px;
  flex-shrink: 0;
}

.App-header-mobile-button {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 99;
  width: 50px;
  height: 50px;
  font-size: 1.8rem;
  background-color: #333333;
  border-radius: 10px;
  color: white;
  padding: 0;
}
.App-header-mobile-button.notVis {
  display: none;
}

.App-header-mobile-background {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000a2;
}

h7 {
  margin-bottom: 0.5rem;
}

@media (max-height: 918px) {
  h7 {
    margin-bottom: 0rem;
  }
}

@media (max-height: 885px) {
  .App-header img {
    width: 67%;
    border-radius: 100%;
    margin: 1rem auto 0.5rem;
  }
}

@media (max-height: 855px) {
  .App-header img {
    width: 56%;
    border-radius: 100%;
    margin: 0.6rem auto 0.1rem;
  }

  .App-header i {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

@media (max-height: 780px) {
  .App-header img {
    width: 50%;
    border-radius: 100%;
    margin: 0.6rem auto 0.1rem;
  }

  .App-header i {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
}

@media (max-height: 750px) {
  .App-header img {
    width: 35%;
    border-radius: 100%;
    margin: 0.6rem auto 0rem;
  }

  .App-header i {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

@media (max-height: 700px) {
  .App-header img {
    width: 30%;
    border-radius: 100%;
    margin: 0.6rem auto 0rem;
  }
}
@media (max-height: 680px) {
  .App-header img {
    width: 20%;
    border-radius: 100%;
    margin: 0.6rem auto 0rem;
  }
}

@media (max-width: 471px) {
  .my-skills-img-left {
    margin: 0 0 0 0;
  }
  .my-skills-img-right {
    margin: 0 0 0 0;
  }
}

@media (max-width: 700px) {
  .about-me-who-am-i-div {
    width: 19rem;
    margin: 0rem;
  }
  .about-me img {
    margin: 0rem;
  }

  .App-header-desktop {
    display: none;
  }
  .App-header-mobile {
    display: block;
    position: fixed;
    left: 0;
    z-index: 99;
  }
  .App-header-mobile-body {
    left: -300px;
    transform: translateX(-300px);
    transition: transform 400ms ease-in;
    top: 0;
    width: 292.38px;
    min-height: 100vh;
  }

  .App-header-mobile-body.slideIn {
    transform: translateX(0);
  }
  .App-header-mobile-body.slideOut {
    transform: translateX(-300px);
  }

  .resume-date {
    flex-basis: 9rem;
  }
  .resume-row li {
    font-size: 0.9rem;
  }
  .resume-row-certs li {
    font-size: 0.9rem;
  }

  .resume-row-info ul,
  .resume-row-certs ul,
  .resume-row ul {
    padding-inline-start: 26px;
  }
}

@media (max-width: 660px) {
  .resume-date {
    flex-basis: 8rem;
  }
  .resume-row li {
    font-size: 0.8rem;
  }
}
